import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "新オフィスのデザインを任された話",
  "date": "2018-10-10T03:02:46.000Z",
  "slug": "entry/2018/10/10/120246",
  "tags": ["medley"],
  "hero": "./2018_10_10.png",
  "heroAlt": "new office"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`先日、新卒エンジニアさんの`}<strong parentName="p">{`内定式(メドレー初！)`}</strong>{`をおこないました。バキバキでキラキラのイケメン揃いなので中途イケメン枠で入社した私にとってこれまでの地位が危ぶまれ戦々恐々としています。開発本部デザイナーの小山です。`}</p>
    <p>{`内定式もですが、大きな変化がメドレーにありデザイナーの役割が広がる機会が訪れたので、この場を借りてお話させていただきます。`}</p>
    <p>{`近年デザイナーは分野を飛び越えた取りくみが求められる職種になりつつあります。クラシカルデザインが中心だった頃、それができるのはスターデザイナーであり、ごく限られた人たちでした。いまではテクノロジーやフレームワークの進歩により、デザイン思考やコンピュテーショーナルデザインなど、デザイナーが関われる分野はさらに広がりを見せています。いちデザイナーの私もそれに応えたいという想いはありつつも取りくむ難しさを感じています。`}</p>
    <p>{`今回は分野を飛び越える難しさを日頃感じている UI デザイナーの私が、`}<strong parentName="p">{`オフィスデザインという普段とは異なる分野`}</strong>{`に取りくんだお話をさせていただきます。`}</p>
    <h1>{`これからの受け皿を設計する`}</h1>
    <p>{`実は先月 9 月上旬に、メドレーは古巣の新六本木ビルを離れ六本木グランドタワーへ`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/133871"
      }}>{`本社移転`}</a>{`しました。私が入社した去年 3 月時点から社員数が 3 倍近く増えたので、「`}<strong parentName="p">{`社員数拡大のためのキャパシティ確保`}</strong>{`」「`}<strong parentName="p">{`設備の充実`}</strong>{`」「`}<strong parentName="p">{`組織の一体感の強化`}</strong>{`」が主に取りくむべき移転の課題でした。その解決のためオフィス専門の設計チームが初期のアーキテクトと施工管理を担当し、空間の方針とデザインと什器のディレクションを`}<a parentName="p" {...{
        "href": "https://unleash.tokyo/2018/08/02/medley_clinics_karte/"
      }}><strong parentName="a">{`マエダ`}</strong></a>{`と私が担当しました。`}</p>
    <p>{`先だって取りくんだのは空間の方針です。この先のメドレーの姿を踏まえ、そこに到達するための受け皿となるように設計しました。`}</p>
    <p>{`これまでメドレーは社会の公器としての意識を持ち、様々なサービスで医療課題に取りくんできました。それが今年で創業 10 年を迎えています。規模も拡大し、さまざまな人がこのメドレーに参画するようになりベンチャー企業としてだけでなく社会の大きな責任を果たす存在になりつつあります。`}</p>
    <p>{`そうしたときの空間の役割として`}<strong parentName="p">{`これまでのベンチャーマインドとこれからの大きな責任を背負う姿勢の両方を意識できる空間づくり`}</strong>{`が良いのではと考え以下のような方針を組み立てました。`}</p>
    <h1>{`可変と不変の両極を横断する空間`}</h1>
    <p>{`急速に成長するためのベンチャーマインドと、拡大していく社会の公器として責任を負う姿勢を同居させるために、ただ新しくするだけではなく、今までのメドレーはしっかり持ち合わせる。そしてそれを日々の業務のなかで行き来できるように空間の方針をつくり、デザインに反映していきました。もちろん制約が多く結果論的な部分はありますが、新しくするために全てを壊すのではなく、かといって古いものを大事に取っておくのでもない、`}<strong parentName="p">{`これまでのメドレーらしさとこれからのメドレーの２つをポジティブに意識し設計`}</strong>{`しました。`}</p>
    <p>{`執務室は旧本社ビルの雰囲気をそのままスライドさせつつ、先に挙げた 3 つの課題を取りくむため機能を拡張しました。もっとも変えたのはエントランスから会議室にかけてです。コーポレートカラーである赤色を一切使わず真っ白な空間にしました。執務室が変えない場所であるなら、ここは変える場所であり、自分たちを一度否定し絶えず新しくしていく場所として位置付けました。これから様々な人と出会える場所としても日々新鮮な気持ちになれると考えたからです。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181010/20181010113717.png",
        "alt": "f:id:medley_inc:20181010113717p:plain",
        "title": "f:id:medley_inc:20181010113717p:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181010/20181010113722.jpg",
        "alt": "f:id:medley_inc:20181010113722j:plain",
        "title": "f:id:medley_inc:20181010113722j:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181010/20181010113729.png",
        "alt": "f:id:medley_inc:20181010113729p:plain",
        "title": "f:id:medley_inc:20181010113729p:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181010/20181010113741.png",
        "alt": "f:id:medley_inc:20181010113741p:plain",
        "title": "f:id:medley_inc:20181010113741p:plain"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181010/20181010113736.png",
        "alt": "f:id:medley_inc:20181010113736p:plain",
        "title": "f:id:medley_inc:20181010113736p:plain"
      }}></img></p>
    <h1>{`空間デザインの独特な難しさと向きあう`}</h1>
    <p>{`この空間をつくるために、この分野特有の壁と向きあいました。UI デザインでは扱わない大きなサイズ感や専門知識を総動員して出来上がる空間を想像する力など、普段の仕事にはない技術や感覚を求められることが多く、独特の難しさを痛感しました。`}</p>
    <p>{`たとえば UI デザインでは簡単にモックアップがつくれますが空間で「よし試しに壁たてるか！」なんてことはできません。仮にモックアップをつくれたとしても UI デザインほどの情報量で仕上がることはなく、そこは培ってきた経験と知識で補わなければなりません。専門のチームがいるので、えーい丸投げ！あとヨロシク！という考えも頭をよぎりましたが、決めるのは私の役目でもあるので、円滑なコラボレーションにするために、その人たちの知識や感覚に追いつくことは急務でした。`}</p>
    <p>{`ここまでの話を聞くと体力的にキツそうと思われるかもしれませんが、実際は現業と通じる部分やデザイナーの感覚を分野を超えて持ち込める部分もありましたので、楽しみながら取りくめました。例えばデザイナーは物の形や色の違いに敏感な種族なので、それが 2D でも 3D でもすぐさま察知できます（程度にもよりますが）。図面と比較してわずかな壁の色や照明の輝度や色、わずかな目地のズレなどなど。`}</p>
    <p>{`とはいえ異なる分野で直感１つで勝負できないのも理解しました。まだまだ学びは多そうですが、この取りくみを日頃の仕事にフィードバックしたいと思います。下記は取りくみを通して同じような機会を得たときに使える備忘録としてまとめてみたポイントです。`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`考えかたの定型化`}</strong>{` - 何かをデザインするのなら進め方に違いはないはず。違っても自分の考えかたの型をもとにカスタマイズする`}</li>
      <li parentName="ul"><strong parentName="li">{`礼儀としての知識`}</strong>{` - よほど未開拓でない限りその分野の専門家がいるはず。コラボレーションするために最低限の礼儀として知識は身につける`}</li>
      <li parentName="ul"><strong parentName="li">{`違和感を無視しない`}</strong>{` - その分野の常識や知識のインプットが追いつかなくても、そこで感じる引っかかりを共有することで周りが立ち返れる`}</li>
    </ul>
    <h1>{`分野が違うだけで別世界ではない`}</h1>
    <p>{`今回は分野を変えることで発見したことや取りくみを、オフィスデザインを通してお話させていただきました。振りかえると備忘録の 3 つや思考の整理の方法など、異なる分野だとしても、自分の専門分野での大事な考え方と共通する部分も多く感じました。もちろん全ての分野を渡り歩いたわけではないので当てはまらない場合はありますし、先述したように独特の難しさもあります。ただこの 3 つがどんな分野でも飛び越えれる最初の道具のうちの１つにすることで、良いスタートが切れるのかなと私は感じています。`}</p>
    <p>{`異なる分野は今までの常識が全く通用しない別の世界ではないことを意識しながら、新しいことに挑戦していきたいと思います。`}</p>
    <p>{`ここまで読んでいただき、ありがとうございました！`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`メドレーが向き合う医療の課題は複雑です。課題を解決するために１つの分野からの一点突破もありますが、多角的な分野からの突破もおこなっています。デザイナーでありながら、エンジニアでありながら、様々な分野に横断しスイッチでき課題解決に向き合うことができます。もしご興味のある方はぜひご連絡ください。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="募集職種 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fjobs%2F" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/jobs/">www.medley.jp</a></cite>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="CREATOR'S STORY | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.medley.jp%2Fteam%2Fcreator-story.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/team/creator-story.html">www.medley.jp</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      